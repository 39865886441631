// PaymentsTable.jsx
import React, { useState } from 'react';
import { Paper, Typography, Box, Button, Modal } from '@mui/material';
import QRCode from 'qrcode.react';

const PaymentsTable = ({ payments }) => {
  // State to track the QR Code modal
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedQrUrl, setSelectedQrUrl] = useState('');

  // Open Modal and set the URL
  const handleOpenQrModal = (url) => {
    setSelectedQrUrl(url);
    setQrModalOpen(true);
  };

  // Close Modal
  const handleCloseQrModal = () => {
    setQrModalOpen(false);
    setSelectedQrUrl('');
  };

  return (
    <div>
      <Paper
        sx={{ p: 4, maxWidth: 800, width: '100%', textAlign: 'center', backgroundColor: 'grey.900', color: 'white' }}
      >
        <Typography variant="h5" gutterBottom>
          Payments
        </Typography>

        {payments
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((payment) => (
            <Box
              key={payment.id}
              sx={{ mb: 2, p: 2, border: '1px solid gray', borderRadius: '8px', backgroundColor: 'grey.800' }}
            >
              <Typography variant="body1">
                <strong>Name:</strong> {payment.customer_name || payment.name}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {payment.customer_email}
              </Typography>
              <Typography variant="body1">
                <strong>Amount:</strong> ${(payment.amount / 100).toFixed(2)}
              </Typography>
              <Typography variant="body1">
                <strong>Description:</strong> {payment.description}
              </Typography>
              <Typography variant="body1">
                <strong>Status:</strong>{' '}
                {payment.status.charAt(0).toUpperCase() + payment.status.slice(1).toLowerCase()}
              </Typography>
              <Typography variant="body1">
                <strong>Created At:</strong>{' '}
                {new Date(payment.created_at).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Typography>
              <Typography variant="body1">
                <a style={{ textDecoration: 'underline', color: 'inherit' }} href={payment.hosted_url}>
                  Link to Payment
                </a>
              </Typography>

              {/* Only show "View QR Code" if hosted_url exists */}
              {payment.hosted_url && (
                <Box sx={{ mt: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenQrModal(payment.hosted_url)}
                  >
                    View QR Code
                  </Button>
                </Box>
              )}
            </Box>
          ))}
      </Paper>

      {/* Modal to display the QR Code */}
      <Modal open={qrModalOpen} onClose={handleCloseQrModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 440,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography style={{marginBottom: "20px"}} variant="h6" gutterBottom>
            Scan to Pay
          </Typography>
          {selectedQrUrl && <QRCode value={selectedQrUrl} size={340} />}
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentsTable;