import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Navbar = ({ user, setUser }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setUser(null);
    navigate('/');
  };

  const menuItems = [
    <MenuItem component={Link} to="/home" key="home">Home</MenuItem>,
    user ? (
      [
        <MenuItem component={Link} to={`/dealerships/${user.dealership?.name.replace(/\s+/g, '-')}`} key="dealership">Dealership</MenuItem>,
        <MenuItem onClick={handleLogout} key="logout">Logout</MenuItem>,
      ]
    ) : (
      <MenuItem component={Link} to="/login" key="login">Login</MenuItem>
    ),
  ];


  return (
    <AppBar position="static">
      <Toolbar>
        {/* Brand Area with "POWERED BY" Stripe */}
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          {/* "CryptAuto" as a Link to "/" */}
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              mr: 2, // margin-right
            }}
          >
            CryptAuto
          </Typography>
  
          {/* "POWERED BY" text */}
          <Typography variant="caption" style={{ marginTop: "6px" }} >
            POWERED BY
          </Typography>
  
          {/* Stripe logo */}
          <Box
            component="img"
            src="/stripe-white.svg" // Make sure the image is in public/stripe-white.svg
            alt="Stripe Logo"
            sx={{ height: 32 }} // Adjust as desired
          />
        </Box>
  
        {/* The rest of your menu items (isMobile check, etc.) below */}
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {menuItems.flat()}
            </Menu>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {menuItems.flat()}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
