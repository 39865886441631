// LandingPage.jsx
import React from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import fetchUser from '../Users/utils';


const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      const userData = await fetchUser();
      setUser(userData);
      setTimeout(() => {
        setLoading(false);
      }, 300); // Ensures the spinner shows for at least 0.5 seconds
    }

    getUser();
  }, []);

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box>

      {/* Hero Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          minHeight: '45vh',
          paddingTop: isMobile ? "10px" : "",
          color: '#fff',
          // switched to vertical gradient
          background: 'linear-gradient(to bottom right, rgb(19, 19, 19) 0%, rgb(48, 48, 48) 50%, rgb(19, 19, 19) 100%)',
        }}
      >
        <Typography variant="h3" style={{ marginTop: "20px" }} sx={{ mb: 2, fontWeight: 'bold' }}>
          CryptAuto
        </Typography>
        <Typography variant="h5" sx={{ mb: 4 }} style={{
          marginLeft: isMobile ? "5%" : "",
          marginRight: isMobile ? "5%" : "",
        }}>
          Empowering Car Dealerships to Accept Crypto without the Hassle
        </Typography>
        {!user &&
          <Button
            component={Link}
            to="/signup"
            variant="contained"
            color="primary"
            size="large"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
          >
            Sign Up Now
          </Button>
        }
        <Box sx={{ mt: 4 }}>
          <img
            src="/cryptauto_banner_transparent.png"
            alt="CryptAuto Banner"
            style={{ width: '100%', maxWidth: '600px', height: 'auto' }}
          />
        </Box>
      </Box>

      {/* Value Proposition Section */}
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Why CryptAuto?
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Card 1 */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '30px',
                borderRadius: '10px',
                boxShadow: '0 0 10px rgba(255, 255, 255, 0.51)',
              }}
            >
              <CardMedia
                sx={{ pt: '56.25%', width: '56%' }}
                image="/usdc.png"
                title="Secure Crypto"
              />
              <CardContent style={{ width: '76%', textAlign: "center" }}>
                <Typography variant="h6" gutterBottom>
                  Accept Crypto Instantly
                </Typography>
                <Typography variant="body2">
                  Offer your customers modern payment options and expand your reach to crypto enthusiasts.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Card 2 */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '30px',
                borderRadius: '10px',
                boxShadow: '0 0 10px rgba(255, 255, 255, 0.51)',
              }}
            >
              <CardMedia
                sx={{ pt: '56.25%', width: '75%' }}
                image="/flying-money.png"
                title="Fast Payouts"
              />
              <CardContent style={{ width: '84%', textAlign: "center" }}>
                <Typography variant="h6" gutterBottom>
                  Fast & Automated Payouts
                </Typography>
                <Typography variant="body2">
                  Receive your funds in USD directly to your bank account in 1-2 business days via Stripe Connect.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '30px',
                borderRadius: '10px',
                boxShadow: '0 0 10px rgba(255, 255, 255, 0.51)',
              }}
            >
              <CardMedia
                sx={{ pt: '56.25%', width: '56%', filter: 'brightness(0) invert(1)' }}
                image="/qr-code-phone-icon.avif"
                title="Easy Invoicing"
              />
              <CardContent style={{ width: '76%', textAlign: "center" }}>
                <Typography variant="h6" gutterBottom>
                  Simplified Invoicing
                </Typography>
                <Typography variant="body2">
                  Generate invoices on the fly, share QR codes, and let your customers pay without friction.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid style={{ display: "flex", justifyContent: "center" }} item xs={12} md={4}>
          <Card
            sx={{
              minHeight: '200px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '20px',
              borderRadius: '10px',
              marginTop: "50px",
              boxShadow: '0 0 30px rgba(88, 254, 110, 0.8)',
              width: "67%"
            }}
          >
            <CardContent style={{ width: isMobile ? "90%" : '76%', textAlign: "center" }}>
              <Typography style={{ textAlign: "center", fontWeight: "bold", marginBottom: "10px" }} variant="h6" gutterBottom>
                No Subscriptions. No Hidden Fees.
              </Typography>
              <Typography variant="body2">
                There are no subscriptions or hidden fees to use CryptAuto. We simply charge a 3% flat fee on all transactions, half of which goes directly to Stripe for their network fee. We enable you to easily add the fee to your customer's generated invoice.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

      </Container>

      {/* How It Works Section */}
      <Box
        sx={{
          background: 'linear-gradient(to bottom, rgb(48, 48, 48), rgb(19, 19, 19))',
          py: 6,
        }}
      >
        <Container>
          <Typography variant="h4" align="center" gutterBottom>
            How It Works
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 4 }}>
            Getting started with CryptAuto is quick and easy
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            {/* Card 1 */}
            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '10px',
                  borderRadius: '10px',
                  boxShadow: '0 0 10px rgba(255, 255, 255, 0.2)',
                }}
              >
                <CardContent style={{ width: "80%" }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }} align="center">
                    1. Sign Up
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }} align="center">
                    Create an account and connect your bank for payouts.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Card 2 */}
            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '10px',
                  borderRadius: '10px',
                  boxShadow: '0 0 10px rgba(255, 255, 255, 0.2)',
                }}
              >
                <CardContent style={{ width: "88%" }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }} align="center">
                    2. Generate Invoice
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }} align="center">
                    Create a crypto invoice, display the QR code, and let your customer pay.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Card 3 */}
            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '10px',
                  borderRadius: '10px',
                  boxShadow: '0 0 10px rgba(255, 255, 255, 0.2)',
                }}
              >
                <CardContent style={{ width: "80%" }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }} align="center">
                    3. Get Paid
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }} align="center">
                    CryptAuto & Stripe convert crypto to USD and deposit funds into your bank in 1-2 days.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* FAQs Section */}
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I sign up and log in?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Just click on the Sign Up button above, fill out your details. You can log in with Google, or use your own email.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I connect my bank account?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                We partner with Stripe Connect. Once logged in, you can click "Connect Bank with Stripe" to complete the onboarding flow.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Can my customers pay with coins other than USDC?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Currently, we only support stablecoin payments through Stripe. We can only accept crypto that Stripe supports, which are USDC (Ethereum, Solana, or Polygon networks) or USDP (Ethereum, Solana networks).
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>When do I receive payouts?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Typically 1-2 business days after a successful payment, once Stripe converts the crypto and initiates a bank transfer.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How secure is CryptAuto?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                We work with trusted e-commerce payment processor Stripe to ensure your customers' crypto payments are secure and reliable.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>What does it cost?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                There are no platform fees, subscriptions, or hidden fees to use CryptAuto. We simply charge a 3% flat fee on all transactions, half of which goes directly to Stripe for their network fee. We enable you to easily add the fee to your invoice amount to pass along to the customer if you wish.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;