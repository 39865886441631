import React, { useState } from 'react';
import { Button, Container, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseUrl from "../url";
import fetchUser from '../Users/utils';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  marginTop: '10rem',
});

function AdditionalInfoPage({ setUser }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [dealershipName, setDealershipName] = useState('');

  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  const handleAdditionalInfoSubmit = async (e) => {
    e.preventDefault();
    if (!dealershipName) {
      toast.error("Dealership name is required.");
      return;
    }

    try {
      const user = await fetchUser();
      const response = await axios.post(`${baseUrl}/api/signup/additional-info`, {
        token,
        userId,
        email: user.email,
        dealershipName,
      });
      const userData = await fetchUser(); // Fetch the user data after submitting additional info
      setUser(userData);
      navigate('/'); // Redirect to home or any other route
    } catch (error) {
      console.error('Failed to submit additional info:', error);
      toast.error('Failed to submit additional info. Please try again.');
    }
  };

  return (
    <StyledContainer>
      <ToastContainer />
      <div style={isMobile ? { width: "60%" } : { width: "30%" }}>
        <form onSubmit={handleAdditionalInfoSubmit}>
          <TextField
            fullWidth
            label="Dealership Name"
            margin="normal"
            value={dealershipName}
            onChange={(e) => setDealershipName(e.target.value)}
            required
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{
              padding: "12px 10px",
              margin: "4px 0px 10px",
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 500,
            }}
          >
            Submit
          </Button>
        </form>
      </div>
    </StyledContainer>
  );
}

export default AdditionalInfoPage;
