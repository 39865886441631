import React, { useState, useEffect } from 'react';
import { Button, Container, TextField, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import baseUrl from "../url";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchUser from '../Users/utils';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  marginTop: '10rem',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  marginTop: '1rem',
  fontSize: '1.2rem',
});

function EmailSignupPage({ setUser }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dealershipName, setDealershipName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !password || !dealershipName) {
      toast.error("All fields are required.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${baseUrl}/api/signup`, {
        firstName,
        lastName,
        email,
        password,
        dealershipName,
      });
      const { token, userId } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      
      // Fetch the user data and set the user state
      const userData = await fetchUser();
      setUser(userData);
      
      navigate('/'); // Redirect to home or any other route
    } catch (error) {
      if (error.response && error.response.data.message === "Please log in with Google Sign In") {
        toast.error("Please sign up in with Google Sign In");
        return
      } else {
        toast.error('Signup failed. Please try again.');
        return
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer>
      <ToastContainer />
      <div style={isMobile ? { width: "60%" } : { width: "30%" }}>
        <form onSubmit={handleEmailSignup}>
          <TextField
            fullWidth
            label="First Name"
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Last Name"
            margin="normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Dealership Name"
            margin="normal"
            value={dealershipName}
            onChange={(e) => setDealershipName(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{
              padding: "12px 10px",
              margin: "4px 0px 10px",
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 500,
            }}
            disabled={loading} // Disable button when loading
          >
            {loading ? <CircularProgress size={24} /> : 'Sign Up'}
          </Button>
        </form>
      </div>
      <StyledLink to="/login" style={{ color: theme.palette.primary.main }}>
        Already have an account? Login
      </StyledLink>
    </StyledContainer>
  );
}

export default EmailSignupPage;
