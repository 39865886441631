import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import baseUrl from '../url';
import fetchUser from '../Users/utils';

const PaymentSuccess = () => {
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  // Get session_id from URL (using `useLocation` hook to access URL parameters)
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get('session_id');
  const { dealershipName } = useParams();

  useEffect(() => {

    setLoading(true);
    const getUser = async () => {
      const userData = await fetchUser();
      setUser(userData);
    }
    getUser();

    if (sessionId) {
      // Call backend to verify payment status and update payment
      axios
        .post(`${baseUrl}/api/stripe/payment-success`, { sessionId })
        .then((response) => {
          setPaymentStatus('success');
        })
        .catch((error) => {
          setError('Failed to verify payment.');
          console.error('Error verifying payment:', error);
        });
    }
    setLoading(false);
  }, [sessionId]);

  if (loading || !paymentStatus) {
    return (
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <CircularProgress />
        <Typography variant="h6">Processing payment...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start', 
        alignItems: 'center',     
        height: '100vh',          
        textAlign: 'center',      
        marginTop: '10rem',
      }}>
      {paymentStatus === 'success' ? (
        <>
          <Typography variant="h4" gutterBottom>
            Payment Processed Successfully!
          </Typography>
          {user && user?.dealership?.name === dealershipName &&
            <Button style={{ marginTop: "3rem"}} variant="contained" color="primary" href={`/dealerships/${dealershipName}`}>
              Back to Dashboard
            </Button>
          }
        </>
      ) : (
        <>
          <Typography variant="h4" color="error" gutterBottom>
            Payment Failed
          </Typography>
          <Typography variant="h6" gutterBottom>
            Something went wrong. Please try again later.
          </Typography>
          {user &&
            <Button style={{ marginTop: "3rem"}} variant="contained" color="secondary" href={`/dealerships/${dealershipName}`}>
              Back to Dashboard
            </Button>
          }
        </>
      )}
    </Box>
  );
};

export default PaymentSuccess;