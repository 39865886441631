import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal, Box, Typography, TextField, CircularProgress, Checkbox } from '@mui/material';
import QRCode from 'qrcode.react';
import baseUrl from '../../url';
import { toast } from 'react-toastify';

const StripePaymentModal = ({ dealership, fetchPayments, userId, stripeConnected }) => {
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('');
  const [paymentDescription, setPaymentDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [includeProcessingFee, setIncludeProcessingFee] = useState(false);

  const handleOpen = () => {
    setPaymentUrl('');
    if (!stripeConnected) {
      toast.error('Please connect your payout bank through Stripe first.');
      return;
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (fetchPayments) {
      fetchPayments();
    }
  };

  const handleCheckboxChange = (e) => {
    setIncludeProcessingFee(e.target.checked);
    let inputPrice = parseFloat(price);
    if (e.target.checked) {
      inputPrice = inputPrice * 1.03;
    } else {
      inputPrice = inputPrice / 1.03;
    }
    setPrice(inputPrice.toFixed(2));
  };

  const handlePayment = async () => {
    try {
      setLoading(true);
      const amountInCents = Math.round(parseFloat(price) * 100); // Convert to cents

      // Request the payment session URL from the backend
      const response = await axios.post(`${baseUrl}/api/stripe/create-crypto-payment`, {
        userId,
        customer_name: customerName,
        customer_email: customerEmail,
        amount: amountInCents,
        currency: 'usd',
        dealership_id: dealership.id,
        description: paymentDescription,
      });

      const { url } = response.data;
      setPaymentUrl(url);
      setLoading(false);
    } catch (error) {
      console.error('Error creating crypto payment:', error);
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen} disabled={!stripeConnected}>
        Create Stripe Invoice
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 440, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          {!paymentUrl && (
            <>
              <Typography variant="h6" gutterBottom>Create Crypto Invoice</Typography>
              <TextField fullWidth label="Customer Name" margin="normal" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
              <TextField fullWidth label="Customer Email" margin="normal" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
              <div>
                <TextField fullWidth label="Price (USD)" margin="normal" value={price} onChange={(e) => {
                  setIncludeProcessingFee(false);
                  setPrice(e.target.value);
                }
                } />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox checked={includeProcessingFee} onChange={handleCheckboxChange} />
                  <Typography variant="body2">Add 3% processing fee</Typography>
                </Box>
              </div>
              <TextField fullWidth label="Description" margin="normal" value={paymentDescription} onChange={(e) => setPaymentDescription(e.target.value)} />
            </>
          )}

          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {!paymentUrl && !loading ? (
              <Button variant="contained" color="primary" onClick={handlePayment}>
                Create Invoice
              </Button>
            ) : loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" href={paymentUrl} target="_blank">
                View Invoice
              </Button>
            )}
          </Box>

          {paymentUrl && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Typography variant="h6">Scan to Pay</Typography>
              <QRCode value={paymentUrl} size={300}/>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default StripePaymentModal;
