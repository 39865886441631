// Footer.js
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'background.paper',
        boxShadow: 'none',
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 'auto',
        py: 3,
        px: { xs: 2, sm: 3 },
      }}
    >
      <Stack
        // Switch from column on mobile to row on larger screens
        direction={{ xs: 'column', sm: 'row' }}
        // On mobile, items stack from top to bottom. On larger screens, space them out.
        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
        alignItems={{ xs: 'center', sm: 'center' }}
        spacing={2}
        maxWidth="lg"
        margin="auto"
      >
        {/* Left side: "© {year} CryptAuto | POWERED BY Stripe" */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="body1" color="text.secondary">
            &copy; {new Date().getFullYear()} CryptAuto
          </Typography>

          <Stack direction="row" alignItems="center">
            <Typography variant="body2" sx={{ mr: 1 }}>
              POWERED BY
            </Typography>
            <Box
              component="img"
              src="/stripe-white.svg"
              alt="Stripe Logo"
              sx={{ height: 35, margin: '0px 0px 4px 0px' }}
            />
          </Stack>
        </Stack>

        {/* Right side: Links */}
        <Stack
          // Keep them in a row for larger screens, but let's have them stack on mobile
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'center' }}
          spacing={{ xs: 1, sm: 6 }}
        >
          <Link href="mailto:help@cryptauto.net" color="inherit">
            help@cryptauto.net
          </Link>
          <Link href="/legal" color="inherit">
            Legal
          </Link>
          <Link href="/faqs" color="inherit">
            FAQs
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;